import React from "react";
import PackageCardAlpha from "./PackageCardAlpha";
import Pagination from "../../common/Pagination";
import config from "../../../config";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PackageSidebarWrap({ dataStore, loading }) {
  if (!dataStore) {
    return (
      <div className="package-sidebar-wrapper pt-120">
        <div className="container">
          <div className="row">
            <div className="row mb-3">
              <Skeleton height={20} width={200} />
            </div>
            <div className="col-lg-12">
              <div className="row g-4">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <Skeleton height={250} /> {/* Altura do esqueleto do pacote */}
                    <Skeleton height={20} style={{ marginTop: '10px' }} /> {/* Título */}
                    <Skeleton height={20} style={{ marginTop: '5px' }} /> {/* Preço */}
                  </div>
                ))}
              </div>
              <div className="text-center mt-4">
                <Skeleton height={40} width={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { message, data } = dataStore;
  const params = new URLSearchParams(window.location.search);

  const continent = params.get('Continent');
  const destination = params.get('Destination');
  const categoryId = params.get('CategoryId');
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');

  let url = ``;

  if (continent !== null) url += `Continent=${continent}&`;
  if (destination !== null) url += `Destination=${destination}&`;
  if (categoryId !== null) url += `CategoryId=${categoryId}&`;
  if (startDate !== null) url += `startDate=${startDate}&`;
  if (endDate !== null) url += `endDate=${endDate}&`;

  const pagination = {
    type: "search",
    urlParams: url,
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    totalItems: data.totalItems,
    totalPages: data.totalPages
  };

  return (
    <>
      <div className="package-sidebar-wrapper pt-120">
        <div className="container">
          <div className="row">
            <div className="row mb-3">
              {loading ? (
                <Skeleton height={20} width={200} />
              ) : (
                <h6>{message}</h6>
              )}
            </div>
            <div className="col-lg-12">
              <div className="row g-4">
                {loading ? (
                  // Exibe Skeleton para cada pacote de viagem
                  Array.from({ length: 6 }).map((_, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <Skeleton height={250} /> {/* Altura do esqueleto do pacote */}
                      <Skeleton height={20} style={{ marginTop: '10px' }} /> {/* Título */}
                      <Skeleton height={20} style={{ marginTop: '5px' }} /> {/* Preço */}
                    </div>
                  ))
                ) : (
                  data.items.map((travelPackage) => (
                    <div className="col-lg-4 col-md-6" key={travelPackage.id}>
                      <PackageCardAlpha
                        image={config.pacoteUrl + travelPackage.coverImage}
                        label={travelPackage.label}
                        date={travelPackage.periodo}
                        title={travelPackage.title !== "" ? travelPackage.title : travelPackage.coverTitle}
                        currency={travelPackage.currency}
                        price={travelPackage.price}
                        id={travelPackage.id}
                      />
                    </div>
                  ))
                )}
              </div>
              {loading ? (
                <Skeleton height={40} style={{ marginTop: '20px' }} /> // Esqueleto para a seção de paginação
              ) : (
                <Pagination data={pagination} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageSidebarWrap;
