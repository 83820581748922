import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import config from "../../../config";
import formatDate from '../../../helpers/formatDate';

// Registrar a fonte
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Definindo os estilos para o PDF
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    position: 'relative', // Habilita o posicionamento absoluto
  },
  watermark: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.1, // Define transparência
    zIndex: -1, // Garante que fique atrás do conteúdo
  },
  watermarkImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
    textAlign: 'justify',
  },
  subtitleCover: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
    textAlign: 'center',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  smallText: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Times-Roman',
  },
  smallTextCover: {
    fontSize: 12,
    margin: 12,
    fontFamily: 'Times-Roman',
    textAlign: 'center',
  },
  italicText: {
    fontStyle: 'italic',
    margin: 12,
    fontSize: 14,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    width: '100%',
    alignSelf: 'center',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    marginBottom: 15,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 15,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableCell: {
    width: '33%',
    padding: 5,
    fontSize: 12,
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
  },
  paragraph: {
    marginBottom: 10,
    marginLeft: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  listItem: {
    marginBottom: 5,
    fontSize: 12,
    marginLeft: 20,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 14,
    fontFamily: 'Oswald',
  },
});

const convertHTMLToTextWithFormatting = (html) => {
  if (typeof html !== 'string' || !html.trim()) {
    return '';
  }

  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  return tempElement.innerHTML
    .replace(/<div[^>]*>/gi, '') // Remove <div>
    .replace(/<\/div>/gi, '\n') // Substitui </div> por nova linha
    .replace(/<p[^>]*>/gi, '\n') // Adiciona nova linha antes de <p>
    .replace(/<\/p>/gi, '\n') // Adiciona nova linha depois de </p>
    .replace(/<br\s*\/?>/gi, '\n') // Substitui <br> por nova linha
    .replace(/<li[^>]*>/gi, '  - ') // Adiciona indentação para itens de lista
    .replace(/<\/li>/gi, '\n') // Adiciona nova linha após cada item
    .replace(/<\/?ul[^>]*>/gi, '\n') // Adiciona nova linha antes e depois de listas
    .replace(/<\/?strong[^>]*>/gi, '') // Remove <strong> sem modificar o texto
    .replace(/&([a-z]+);/gi, (match, entity) => { // Substitui caracteres especiais HTML
      const entities = {
        nbsp: ' ',
        eacute: 'é',
        otilde: 'õ',
        aacute: 'á',
        iacute: 'í',
        uacute: 'ú',
        ccedil: 'ç',
      };
      return entities[entity] || match; // Retorna o caractere ou mantém original
    })
    .replace(/<\/?.*?>/gi, '') // Remove qualquer outra tag HTML residual
    .trim();
};

const renderHTMLWithStyles = (html) => {
  if (typeof html !== 'string' || !html.trim()) {
    return null;
  }

  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  const elements = [];
  const processNode = (node) => {
    switch (node.nodeName) {
      case 'P':
        elements.push(
          <Text key={elements.length} style={styles.paragraph}>
            {node.textContent.trim()}
          </Text>
        );
        break;
      case 'UL':
        Array.from(node.children).forEach(processNode); // Processa <li> dentro de <ul>
        break;
      case 'LI':
        elements.push(
          <Text key={elements.length} style={styles.listItem}>
            - {node.textContent.trim()}
          </Text>
        );
        break;
      case 'STRONG':
        elements.push(
          <Text key={elements.length} style={styles.bold}>
            {node.textContent.trim()}
          </Text>
        );
        break;
      default:
        elements.push(
          <Text key={elements.length} style={styles.text}>
            {node.textContent.trim()}
          </Text>
        );
    }
  };

  Array.from(tempElement.childNodes).forEach(processNode);

  return elements;
};

const Watermark = () => (
  <View fixed style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', opacity: 0.1 }}>
    <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={config.assetsUrl + "logo-operadora.png"} />
  </View>
);

const MyDocument = ({ data }) => (
  <Document>
    <Page wrap style={styles.body}>
      <Watermark />
      {/* <View
          render={({ pageNumber, totalPages }) => (
            <View style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', opacity: 0.1 }}>
          <Image style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={config.assetsUrl + "logo-operadora.png"} />
        </View>
          )}
          fixed
        /> */}

      {/* Cabeçalho */}
      <View wrap={false}><Text style={styles.header}>Operadora Ponto Com</Text></View>

      {/* Título e Imagem */}
      <View wrap={false}><Text style={styles.title}>{data.title}</Text></View>
      <View wrap={false}><Text style={styles.subtitleCover}>{data.coverTitle}</Text></View>
      <View ><Image style={styles.image} src={`${config.pacoteUrl}${data.coverImage}`} /></View>

      {/* Descrição */}
      <View><Text style={styles.text}>{convertHTMLToTextWithFormatting(data.description)}</Text></View>

      {/* Roteiro de Viagem */}
      {data.travelPlanDays.map((day, index) => (
        <View key={index} wrap={false}>
          <Text style={styles.subtitle}>Dia {day.dayNumber}: {day.dayDescription}</Text>
          <Text style={styles.text}>{convertHTMLToTextWithFormatting(day.description)}</Text>
        </View>
      ))}

      {/* O que está incluso */}
      <View >
        <Text style={styles.subtitle}>O que está incluso?</Text>
        {renderHTMLWithStyles(data.includedIn)}
      </View>


      {/* Informações adicionais */}
      {data.additionalInformations && (
        <View wrap={false}>
          <Text style={styles.text}>{convertHTMLToTextWithFormatting(data.additionalInformations)}</Text>
        </View>
      )}

      {/* Acomodações */}
      <View wrap={false}>
        <Text style={styles.subtitle}>Acomodações</Text>
        <View style={styles.table}>
          <View wrap={false} style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.tableHeader]}>Single</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Double</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Triple</Text>
            <Text style={[styles.tableCell, styles.tableHeader]}>Saídas</Text>
          </View>
          {data.accommodationsDetails.map((accommodation, index) => (
            <View wrap={false} key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>
                {accommodation.priceToSingle > 0 ? `${data.currency} ${accommodation.priceToSingle}` : "Consultar"}
                {accommodation.priceToSingle > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""}
              </Text>
              <Text style={styles.tableCell}>
                {accommodation.priceToDouble > 0 ? `${data.currency} ${accommodation.priceToDouble}` : "Consultar"}
                {accommodation.priceToDouble > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""}  
              </Text>
              <Text style={styles.tableCell}>
                {accommodation.priceToTriple > 0 ? `${data.currency} ${accommodation.priceToTriple}` : "Consultar"}
                {accommodation.priceToTriple > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""} 
              </Text>
              <Text style={styles.tableCell}>{accommodation.departure}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const PackagePDF = ({ data }) => {
  if (!data) {
    return <div>Dados do pacote não disponíveis.</div>;
  }

  return (
    <div>
      <PDFViewer width="100%" height="600px">
        <MyDocument data={data} />
      </PDFViewer>
    </div>
  );
};

export default PackagePDF;
