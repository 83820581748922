
import { Link } from "react-router-dom";
import config from "../../config";

function Footer(props) {

  // smooth scrol
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const whatsappNumber = '5551999738501';
  const message = encodeURIComponent(`Olá, gostaria de saber mais sobre seus serviços. `);


  return (
    <>
      <div className={props.className}>
        <div className="footer-main-wrapper">
          <div className="footer-vactor">
            <img src={process.env.PUBLIC_URL + "/images/banner/footer-bg.png"} alt="FooterIMG" />
          </div>
          <div className="container">
            <div className="row justify-content-center g-4">
              <div className="col-lg-4">
                <div className="footer-about text-lg-start text-center">
                  <div className="footer-social-wrap">
                    <h5>Nos siga nas redes sociais:</h5>
                    <ul className="footer-social-links justify-content-lg-start justify-content-center">
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/operadorapontocom"
                          target="_blank"
                        >
                          <i className="bx bxl-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/operadorapontocom"
                          target="_blank"
                        >
                          <i className="bx bxl-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href={`https://wa.me/${whatsappNumber}?text=${message}`}
                          target="_blank"
                        >
                          <i className="bx bxl-whatsapp" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h3 className="footer-widget-title">Operadora Ponto Com</h3>
                  <ul className="footer-links">
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        Sobre nós
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Tipos de Pacotes</h4>
                  <ul className="footer-links">
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/package/1?PageSize=12&PageNumber=1`}
                      >
                        Viagens em Grupo
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/package/2?PageSize=12&PageNumber=1`}
                      >
                        Saídas Programadas
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <>
              </>
            </div>
            <div className="footer-contact-wrapper">
              <h5>Entre em contato conosco:</h5>
              <ul className="footer-contact-list">
                <li>
                  <i className="bi bi-telephone-x" />{" "}
                  <a rel="noopener noreferrer" href="tel:(51)99973-8501">
                  +55 (51) 99973-8501
                  </a>
                </li>
                <li>
                  <i className="bi bi-envelope-open" />{" "}
                  <a href="mailto:reservas@operadora.tur.br">reservas@operadora.tur.br</a>
                </li>
                <li>
                  <i className="bi bi-geo-alt" />{" "}
                  <Link to={"#"}>Rua Jorge Salis Goulart 85, Jardim São Pedro, Porto Alegre -  Brasil</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4 col-md-6 order-lg-1 order-3 ">
                <div className="copyright-link text-lg-start text-center">
                  <p>
                    Copyright 2024 - Todos os direitos reservados Operadora Ponto Com
                  </p>
                </div>
              </div>
              <div className="col-lg-4  order-lg-2 order-1">
                <div className="footer-logo text-center">
                  <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                    <img src={config.assetsUrl + "logo-operadora.png"} alt="FooterIMG" style={{ maxWidth: 100 }}/>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
