import React, { useState } from "react";
import formatDate from '../../../helpers/formatDate';
import Loading from "../../common/Loading";
import PackagePDF from "./PackagePDF";

function PackageDetailsTab({ data }) {  
  const [activeTab, setActiveTab] = useState("pill-body1");

  if (data === null || data === undefined) {
    return <Loading />;
  }

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <div className="package-details-tabs">
        <ul
          className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-package1"
              data-bs-toggle="pill"
              data-bs-target="#pill-body1"
              type="button"
              role="tab"
              aria-controls="pill-body1"
              aria-selected="true"
              onClick={() => handleTabChange("pill-body1")}
            >
              <i className="bi bi-info-lg" /> Informações
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package2"
              data-bs-toggle="pill"
              data-bs-target="#pill-body2"
              type="button"
              role="tab"
              aria-controls="pill-body2"
              aria-selected="false"
              onClick={() => handleTabChange("pill-body2")}
            >
              <i className="bi bi-file-earmark-spreadsheet" />
              Detalhes do dia-a-dia
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-package3"
              data-bs-toggle="pill"
              data-bs-target="#pill-body3"
              type="button"
              role="tab"
              aria-controls="pill-body3"
              aria-selected="false"
              onClick={() => handleTabChange("pill-body3")}
            >
              <i className="bi bi-file-earmark-pdf" />
              Baixar Roteiro PDF
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/* package info tab */}
          <div
            className="tab-pane fade show active package-info-tab mt-5"
            id="pill-body1"
            role="tabpanel"
            aria-labelledby="pills-package1"
          >
            <h3 className="d-subtitle">Detalhes do pacote</h3>
            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            <table className="table package-info-table mb-0">
              <tbody>
                <tr>
                  <th>Roteiro</th>
                  <td>{data.title}</td>
                </tr>
                <tr>
                  <th>Período</th>
                  <td>
                    {data.periodo && data.periodo.trim() !== "" ? data.periodo : "Consultar datas"}
                  </td>
                </tr>
                <tr>
                  <th>Detahes do pacote</th>
                  <td>
                    <span dangerouslySetInnerHTML={{ __html: data.includedIn }} />
                  </td>
                </tr>
                {data.accommodationsDetails && data.accommodationsDetails.length > 0 && (
                <tr>
                  <th>Valores</th>
                  <td>
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          {/* <th>Destino</th> */}
                          <th>Single</th>
                          <th>Double</th>
                          <th>Triple</th>
                          <th>Saídas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.accommodationsDetails.map((accommodation, index) => (
                          <tr key={index}>
                            {/* <td>{accommodation.destination}</td> */}
                            <td>
                              {accommodation.priceToSingle > 0 ? `${data.currency} ${accommodation.priceToSingle}` : "Consultar"}
                              {accommodation.priceToSingle > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""}
                            </td>
                            <td>
                              {accommodation.priceToDouble > 0 ? `${data.currency} ${accommodation.priceToDouble}` : "Consultar"}
                              {accommodation.priceToDouble > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""}
                            </td>
                            <td>
                              {accommodation.priceToTriple > 0 ? `${data.currency} ${accommodation.priceToTriple}` : "Consultar"}
                              {accommodation.priceToTriple > 0 && accommodation.tax > 0 ? `+ ${accommodation.tax} taxas` : ""}
                            </td>
                            <td>{accommodation.departure}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
                )}
                {data.additionalInformations && (
                  <tr>
                    <th>Informações adicionais</th>
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.additionalInformations }} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* package plans tab */}
          <div
            className="tab-pane fade package-plan-tab tab-body"
            id="pill-body2"
            role="tabpanel"
            aria-labelledby="pills-package2"
          >
            <h3 className="d-subtitle">Explore o Roteiro da Sua Viagem</h3>
            <p>
              Bem-vindo à sua próxima grande aventura! Aqui, você encontrará o roteiro detalhado da sua jornada incrível.
              Prepare-se para explorar destinos emocionantes, descobrir culturas fascinantes e criar memórias inesquecíveis. 
              Cada dia será uma nova página deste capítulo inesquecível da sua vida. Aprecie o itinerário e comece a contagem 
              regressiva para a sua viagem dos sonhos!
            </p>

            <div className="accordion plans-accordion" id="planAccordion">
              {data.travelPlanDays.map((day) => (
                <div className={`accordion-item plans-accordion-single`} key={day.id}>
                  <div className="accordion-header" id={`planHeading${day.dayNumber}`}>
                    <div
                      className={`accordion-button ${day.dayNumber === 1 ? '' : 'collapsed'}`}
                      data-bs-toggle="collapse"
                      data-bs-target={`#planCollapse${day.dayNumber}`}
                      role="navigation"
                    >
                      <div className="paln-index-circle">
                        <h4>{day.dayNumber < 10 ? `0${day.dayNumber}` : day.dayNumber}</h4>
                      </div>
                      <div className="plan-title">
                        <h5>{day.dayDescription}</h5>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`planCollapse${day.dayNumber}`}
                    className={`accordion-collapse collapse ${day.dayNumber === 1 ? 'show' : ''}`}
                    aria-labelledby={`planHeading${day.dayNumber}`}
                    data-bs-parent="#planAccordion"
                  >
                    <div className="accordion-body plan-info">
                      <p dangerouslySetInnerHTML={{ __html: day.description }} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Package PDF tab */}
          <div
            className="tab-pane fade package-pdf-tab tab-body"
            id="pill-body3"
            role="tabpanel"
            aria-labelledby="pills-package3"
          >
            <div className="mt-3">
              <PackagePDF data={data} /> {/* Componente que renderiza o PDF */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageDetailsTab;