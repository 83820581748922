import React from "react";
import Pagination from "../../common/Pagination";
import PackageCard from "./PackageCard";
import config from "../../../config";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PackageGridWrapper({ type, dataStore, loading }) {
  if (!dataStore) {
    return (
      <div className="package-wrapper pt-110">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-sm-10">
              <div className="section-head-alpha">
                <Skeleton height={40} />
                <Skeleton count={3} height={20} style={{ marginTop: '10px' }} />
              </div>
            </div>
          </div>
          <div className="row g-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <Skeleton height={250} />
                <Skeleton height={20} style={{ marginTop: '10px' }} /> {/* Título */}
                <Skeleton height={20} style={{ marginTop: '5px' }} /> {/* Preço */}
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <Skeleton height={40} width={200} />
          </div>
        </div>
      </div>
    );
  }

  const { data } = dataStore;
  const pagination = {
    type: type,
    pageSize: data.pageSize,
    pageNumber: data.pageNumber,
    totalItems: data.totalItems,
    totalPages: data.totalPages
  };

  return (
    <>
      {/* ===============  Package grid area start =============== */}
      <div className="package-wrapper pt-110">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-sm-10">
              <div className="section-head-alpha">
                {loading ? (
                  <>
                    <Skeleton height={40} />
                    <Skeleton count={3} height={20} style={{ marginTop: '10px' }} />
                  </>
                ) : (
                  <>
                    <h2>{type === "1" ? "Viagens em Grupo" : "Saídas Programadas"}</h2>
                    <p>
                      {type === "1" ? "Explore aventuras exclusivas para grupos. Encontre pacotes personalizados para ocasiões especiais." : 
                      "Escolha entre uma variedade de itinerários pré-agendados para viajantes individuais e pequenos grupos. Reserve sua próxima aventura com facilidade."}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row g-4">
            {loading ? (
              // Exibe Skeleton se estiver carregando
              Array.from({ length: 6 }).map((_, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <Skeleton height={250} />
                  <Skeleton height={20} style={{ marginTop: '10px' }} /> {/* Título */}
                  <Skeleton height={20} style={{ marginTop: '5px' }} /> {/* Preço */}
                </div>
              ))
            ) : (
              // Renderiza os pacotes de viagem se não estiver carregando
              data.items.map((travelPackage) => (
                <div className="col-lg-4 col-md-6" key={travelPackage.id}>
                  <PackageCard
                    image={config.pacoteUrl + travelPackage.coverImage}
                    label={travelPackage.label}
                    date={travelPackage.periodo}
                    title={travelPackage.title !== "" ? travelPackage.title : travelPackage.coverTitle}
                    currency={travelPackage.currency}
                    price={travelPackage.price}
                    id={travelPackage.id}
                  />
                </div>
              ))
            )}
          </div>
          {loading ? (
            // Exibe Skeleton para a seção de paginação
            <div className="text-center mt-4">
              <Skeleton height={40} width={200} />
            </div>
          ) : (
            <Pagination data={pagination} />
          )}
        </div>
      </div>
      {/* ===============  Package grid area end =============== */}
    </>
  );
}

export default PackageGridWrapper;
