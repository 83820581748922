import React, { useState, useEffect } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import PackageGridWrapper from "./PackageGridWrapper";
import { getPackageByType } from "../../../services/packageServices";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useErrorContext } from '../../../error';
import { decryptData } from "../../../utils/secureEncoder";

function PackageGrid() {
  const { error, setErrorState, clearErrorState } = useErrorContext();
  const [dataStore, setDataStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const { encodedId } = useParams();
  const [decodedId, setDecodedId] = useState(null);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pageSize = queryParams.get("PageSize");
  const pageNumber = queryParams.get("PageNumber");
  const [name, setName] = useState("");

  useEffect(() => {
    if (encodedId) {
      try {
        const decoded = decryptData(encodedId);
        setDecodedId(decoded);
      } catch (error) {
        setErrorState(true);
      }
    }
  }, [encodedId]);

  useEffect(() => {
    if (decodedId) {
      setLoading(true);
      clearErrorState(); 
      getPackageByType(decodedId, pageSize, pageNumber)
        .subscribe(
          (response) => {
            setDataStore(response);
            updateName(decodedId);
            setLoading(false);
            setErrorState(false);
          },
          (error) => {
            setErrorState(true);
            setLoading(false);
          }
        );
    }
  }, [decodedId]);

  useEffect(() => {
    if (decodedId) {
      setLoading(true);
      clearErrorState(); 
      getPackageByType(decodedId, pageSize, pageNumber)
        .subscribe(
          (response) => {
            setDataStore(response);
            updateName(decodedId);
            setLoading(false);
            setErrorState(false);
          },
          (error) => {
            setErrorState(true);
            setLoading(false);
          }
        );
    }
  }, [decodedId, pageNumber]);

  useEffect(() => {
    if (decodedId) {
      setLoading(true);
      clearErrorState(); 
      getPackageByType(decodedId, pageSize, pageNumber)
        .subscribe(
          (response) => {
            setDataStore(response);
            updateName(decodedId);
            setLoading(false);
            setErrorState(false);
          },
          (error) => {
            setErrorState(true);
            setLoading(false);
          }
        );
    }
  }, []);

  function updateName(decodedId){
    setName(decodedId === "1" ? "Viagens em Grupo" : "Saídas Programadas");
  }

  return (
    <>
        {
          !error ? (
          <>
            <Breadcrumb name={name} type={"Generic"} loading={loading} />
            <PackageGridWrapper type={decodedId} dataStore={dataStore} loading={loading}/>
          </>) : null
        }
    </>
  );
}

export default PackageGrid;
