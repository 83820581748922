import React, { useEffect, useState } from "react";
import PackageSidebarWrap from "./PackageSidebarWrap";
import { search } from "../../../services/packageServices";
import { useErrorContext } from '../../../error';

function PackageSIdebar() {
  const { error, setErrorState, clearErrorState } = useErrorContext();
  const [dataStore, setDataStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const text = queryParams.get('Text');
  const continent = queryParams.get('Continent');
  const destination = queryParams.get('Destination');
  const categoryId = queryParams.get('CategoryId');
  const startDate = queryParams.get('startDate');
  const endDate = queryParams.get('endDate');
  const pageSize = queryParams.get('PageSize');
  const pageNumber = queryParams.get('PageNumber');

  useEffect(() => {
    clearErrorState();
    setLoading(true);
    search(text, continent, destination, categoryId, startDate, endDate, pageSize, pageNumber)
      .subscribe(
        (response) => {
          setDataStore(response);
          setLoading(false);
          setErrorState(false);
        },
        (error) => {
          setErrorState(true);
          setLoading(false);
        }
      )
  }, []);

  useEffect(() => {
    clearErrorState();
    setLoading(true);
    search(text, continent, destination, categoryId, startDate, endDate, pageSize, pageNumber)
      .subscribe(
        (response) => {
          setDataStore(response);
          setLoading(false);
          setErrorState(false);
        },
        (error) => {
          setErrorState(true);
          setLoading(false);
        }
      )
  }, [text, continent, destination, categoryId, startDate, endDate, pageNumber]);
  
  return (
    <>
      {!error ? (
        <>
          {/* <Breadcrumb name="Package Sidebar" /> */}
          <PackageSidebarWrap dataStore={dataStore} loading={loading} />
        </>
      ) : null}
    </>
  );
  
}

export default PackageSIdebar;
