import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import config from "../../config";
import { encryptData } from "../../utils/secureEncoder";
import { getExchangeRates } from "../../services/cotationService";
import { useHistory } from "react-router-dom";

function HomeHeader() {
  const [searchActive, setSearchActive] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [userDropdown, setuserDropdown] = useState(0);
  const [mobileSideberMenu, setMobileSideberMenu] = useState(0);
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [rates, setRates] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const subscription = getExchangeRates().subscribe({
        next: (data) => setRates(data),
        error: (err) => setError(err),
    });

    return () => subscription.unsubscribe();
}, []);

  function reducer(state, action) {
    switch (action.type) {
      case "home": {
        const newState = { ...state, activeMenu: "home" };
        return newState;
      }
      case "about": {
        const newState = { ...state, activeMenu: "about" };
        return newState;
      }
      case "package": {
        const newState = { ...state, activeMenu: "package" };
        return newState;
      }
      case "blog": {
        const newState = { ...state, activeMenu: "blog" };
        return newState;
      }
      case "destination": {
        const newState = { ...state, activeMenu: "destination" };
        return newState;
      }
      default:
        return { activeMenu: " " };
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSearch = () => {
    const queryParams = [];
    if(searchValue.trim() === ""){
      alert("Por favor, digite algo no campo de busca.");
    } else {
      queryParams.push(`Text=${searchValue}`);
      
      queryParams.push('PageSize=12&PageNumber=1');

      history.push(`/package/search?${queryParams.join('&')}`);

      setSearchActive(false);
    }
  };

  const handleSearchBar = () => {
    if (searchActive === false || searchActive === 0) {
      setSearchActive(1);
    } else {
      setSearchActive(false);
    }
  };

  const handleMobileSiderbar = () => {
    if (mobileSideberMenu === false || mobileSideberMenu === 0) {
      setMobileSideberMenu(1);
    } else {
      setMobileSideberMenu(false);
    }
  };

  const handleClick = (event) => {
        event.preventDefault();
  };

  const encodedGruposeBloqueiosId = encryptData(1);
  const encodedSaidasRegularesId = encryptData(2);

  if (!rates) {
    return <div></div>;
  }
  
  return (
    <>
      <div
        className={searchActive === 1 ? "mobile-search slide" : "mobile-search"}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>Qual roteiro você está procurando?</label>
              <input
                mode="search"
                type="text"
                placeholder="Buscar roteiros"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div className="search-cross-btn" onClick={handleSearchBar}>
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <header>
        <div className="header-area header-style-three position-absolute w-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <div className="nav-logo d-flex justify-content-between align-items-center">
                  <a href="/">
                    <img
                      src={config.assetsUrl + "logo-operadora.com.png"}
                      alt="logo"
                      style={{ maxWidth: 180 }}
                    />
                  </a>
                  <div className="mobile-menu d-flex mobile-visible">
                    <div className="d-flex align-items-center">
                    <div className="nav-right-icons gap-3">
                        <div className="header-search search-btn" onClick={handleSearchBar}>
                          <i className="bx bx-search-alt" />
                        </div>
                      </div>
                      <button
                        className={
                          mobileSideberMenu === 1
                            ? "hamburger d-block d-xl-none h-active"
                            : "hamburger d-block d-xl-none"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          handleMobileSiderbar();
                        }}
                        type="button"
                      >
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                  <span class="navbar-brand">
                  </span>
                    <div className="mobile-visible" style={{color: '#FFF', fontSize: '16px', marginLeft: '5%', float: 'left', width: '300px'}}>
                      <div style={{float: 'left', width: '150px'}}>
                        Dólar: R$ {rates.USD.bid} 
                      </div>
                      <div style={{float: 'left', width: '150px'}}>
                        Euro: R$ {rates.EUR.bid}
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-xxl-4 col-xl-8 col-lg-9 col-md-8 col-sm-12">
                <nav className="main-nav d-flex">
                  <ul>
                    <li onClick={scrollTop}>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li onClick={scrollTop}>
                      <NavLink to={`${process.env.PUBLIC_URL}/about`}>Sobre nós</NavLink>
                    </li>
                    <li className="has-child-menu" onClick={() => dispatch({ type: "package" })}>
                      <Link to={"#"} onClick={handleClick}>
                        Pacotes
                      </Link>
                      <ul
                        className={
                          state.activeMenu === "package"
                            ? "sub-menu d-block"
                            : "sub-menu d-none"
                        }
                      >
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/package/${encodedGruposeBloqueiosId}?PageSize=12&PageNumber=1`}
                            onClick={scrollTop}
                          >
                            Viagens em Grupo
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={`${process.env.PUBLIC_URL}/package/${encodedSaidasRegularesId}?PageSize=12&PageNumber=1`}
                            onClick={scrollTop}
                          >
                            Saídas Programadas
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>
                        Contato
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              
              <div className="col-xFxl-1 col-xl-5 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right d-flex">
                    <div className="hotline-info">
                      <span>Dólar:</span>
                      <h6>R$ {rates.USD.bid}</h6>
                    </div>
                    <div className="hotline-info">
                      <span>Euro:</span>
                      <h6>R$ {rates.EUR.bid}</h6>
                    </div>
                    <div className="hotline-info">
                      <i className="bi bi-whatsapp"></i>
                      <h6>
                        <a href="https://wa.me/5551999738501" target="_blank">(51)99973-8501</a>
                      </h6>
                    </div>
                  </div>
                  <div className="nav-right-icons gap-3">
                    <div className="header-search search-btn" onClick={handleSearchBar}>
                      <i className="bx bx-search-alt" />
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HomeHeader;
