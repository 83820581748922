import React from "react";
import { Link } from "react-router-dom";
import { encryptData } from "../../../utils/secureEncoder";

function PackageCard(props) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <>
      <div className="package-card-alpha">
        <div className="package-thumb">
        {props.label && ( 
          <div className="package-label">{props.label}</div>
        )}
          <Link
            onClick={scrollTop}
            to={`${process.env.PUBLIC_URL}/package-details/${encryptData(props.id)}`}
          >
            <img src={props.image} alt="images" />
          </Link>
          <p className="card-lavel">
            <i className="bi bi-calendar" /> <span>{props.date}</span>
          </p>
        </div>
        <div className="package-card-body">
          <h3 className="p-card-title">
            <Link
              onClick={scrollTop}
              to={`${process.env.PUBLIC_URL}/package-details/${encryptData(props.id)}`}
            >
              {props.title}
            </Link>
          </h3>
          <div className="p-card-bottom">
            <div className="book-btn">
              <Link
                onClick={scrollTop}
                to={`${process.env.PUBLIC_URL}/package-details/${encryptData(props.id)}`}
              >
                Mais Detalhes <i className="bx bxs-right-arrow-alt" />
              </Link>
            </div>
            <div className="p-card-info">
              {props.price > 0 ? (
                <>
              <span>à partir de</span>
              <h6>
                {props.currency + props.price} <span>Por Pessoa</span>
              </h6>
              </>
              ) : (
                <h6>
                  Consultar valores
              </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageCard;
