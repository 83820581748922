import React from "react";
import { Link } from "react-router-dom";
import config from "../../../config";
import { encryptData } from "../../../utils/secureEncoder";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

function PackageArea({ dataStore, type, loading }) {
  if (!dataStore) {
    return (
      <div className="package-area package-style-two pt-110 chain">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-8 col-sm-10">
              <div className="section-head-alpha">
                <Skeleton height={40} />
                <Skeleton count={3} height={20} style={{ marginTop: '10px' }} />
              </div>
            </div>
            <div className="col-lg-4 col-sm-10">
              <div className="package-btn text-lg-end">
                <Skeleton height={40} width={200} />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {Array.from({ length: 3 }).map((_, index) => (
              <div className="col-lg-4 col-md-6 col-sm-10 fadeffect" key={index}>
                <Skeleton height={300} />
                <Skeleton height={20} style={{ marginTop: '10px' }} />
                <Skeleton height={20} style={{ marginTop: '5px' }} />
              </div>
            ))}
          </div>
          <div className="package-page-btn text-center mt-60">
            <Skeleton height={40} width={200} />
          </div>
        </div>
      </div>
    );
  }

  const { message, data } = dataStore;

  return (
    <>
      <div className={`package-area package-style-two pt-110 pb-30 chain ${type === 1 ? "grupos-bloqueios" : "saidas-regulares"}`}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-8 col-sm-10">
              <div className={`${type === 1 ? "section-head-alpha" : "section-head-alpha-white"}`}>
                {loading ? (
                  <>
                    <Skeleton height={40} />
                    <Skeleton count={3} height={20} style={{ marginTop: '10px' }} />
                  </>
                ) : (
                  <>
                    <h2>{type === 1 ? "Viagens em Grupo" : "Saídas Programadas"}</h2>
                    <p>
                      {type === 1 ? "Explore aventuras exclusivas para grupos. Encontre pacotes personalizados para ocasiões especiais." : 
                      "Escolha entre uma variedade de itinerários pré-agendados para viajantes individuais e pequenos grupos. Reserve sua próxima aventura com facilidade."}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-sm-10">
              <div className="package-btn text-lg-end">
                {loading ? (
                  <Skeleton height={40} width={200} />
                ) : (
                  <a
                    href={`/package/${encryptData(type)}?PageSize=12&PageNumber=1`}
                    className="button-fill-primary all-package-btn"
                  >
                    Ver todos os pacotes
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {loading ? (
              Array.from({ length: 3 }).map((_, index) => (
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect" key={index}>
                  <Skeleton height={300} />
                  <Skeleton height={20} style={{ marginTop: '10px' }} />
                  <Skeleton height={20} style={{ marginTop: '5px' }} />
                </div>
              ))
            ) : (
              data.items.map((travelPackage) => (
                <div className="col-lg-4 col-md-6 col-sm-10 fadeffect" key={travelPackage.id}>
                  <PackageCardBeta
                    image={config.pacoteUrl + travelPackage.coverImage}
                    label={travelPackage.label}
                    time={travelPackage.periodo}
                    title={travelPackage.title !== "" ? travelPackage.title : travelPackage.coverTitle}
                    currency={travelPackage.currency}
                    price={travelPackage.price}
                    id={encryptData(travelPackage.id)}
                  />
                </div>
              ))
            )}
          </div>
          <div className="package-page-btn text-center mt-60">
            {loading ? (
              <Skeleton height={40} width={200} />
            ) : (
              <a href={`/package/${encryptData(type)}?PageSize=12&PageNumber=1`} className="button-fill-round">
                Ver todos
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function PackageCardBeta(props) {
  return (
    <>
      <div className="package-card-beta">
        <div className="package-thumb">
          {props.label && ( 
            <div className="package-label">{props.label}</div>
          )}
          <Link
            to={`${process.env.PUBLIC_URL}/package-details/${props.id}`}
            onClick={scrollTop}
          >
            <img
              src={`${process.env.PUBLIC_URL}${props.image}`}
              alt="package-details img"
            />
          </Link>
          <p className="card-lavel">
            <i className="bi bi-calendar" /> <span>{props.time}</span>
          </p>
        </div>
        <div className="package-card-body">
          <h3 className="p-card-title">
            <Link
              to={`${process.env.PUBLIC_URL}/package-details/${props.id}`}
              onClick={scrollTop}
            >
              {props.title}
            </Link>
          </h3>
          <div className="p-card-bottom">
            <div className="book-btn">
              <Link
                to={`${process.env.PUBLIC_URL}/package-details/${props.id}`}
                onClick={scrollTop}
              >
                Mais Detalhes <i className="bx bxs-right-arrow-alt" />
              </Link>
            </div>
            <div className="p-card-info">
              {props.price > 0 ? (
                <>
              <span>à partir de</span>
              <h6>
                {props.currency + props.price} <span>Por Pessoa</span>
              </h6>
              </>
              ) : (
                <h6>
                  Consultar valores
              </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageArea;
