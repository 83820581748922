import axios from 'axios';
import { Observable } from 'rxjs';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function fetchWithRetry(url, retries = MAX_RETRIES) {
    return axios.get(url).catch((error) => {
      if (
        retries > 0 && 
        error.response && 
        (error.response.status === 502 || error.response.status === 500)
      ) {
        return new Promise((resolve) => setTimeout(resolve, RETRY_DELAY)).then(() =>
          fetchWithRetry(url, retries - 1)
        );
      }
      throw error;
    });
  }


export function getExchangeRates() {
    return new Observable((subscriber) => {
      fetchWithRetry(`https://economia.awesomeapi.com.br/all/USD-BRL,EUR-BRL?nocache=${new Date().getTime()}`)
            .then(response => {
                const data = response.data;

                if (data.USD) {
                    data.USD.bid = (parseFloat(data.USD.bid) + 0.20).toFixed(4);
                    data.USD.ask = (parseFloat(data.USD.ask) + 0.20).toFixed(4);
                }

                if (data.EUR) {
                    data.EUR.bid = (parseFloat(data.EUR.bid) + 0.20).toFixed(4);
                    data.EUR.ask = (parseFloat(data.EUR.ask) + 0.20).toFixed(4);
                }

                subscriber.next(data);
                subscriber.complete();
            })
            .catch(error => {
                subscriber.error(error);
            });
    });
}
