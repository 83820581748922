import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { getAllContinentsWithDestinations } from "../../../services/searchServices";
import { useHistory } from "react-router-dom";
import { useErrorContext } from '../../../error';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SearchBar() {
  const { error, setErrorState, clearErrorState } = useErrorContext();
  const history = useHistory();
  const [selectedDestination, setSelectedDestination] = useState('');
  const [selectedTravelType, setSelectedTravelType] = useState('');
  const [dateRange, setDateRange] = useState([new Date(), null]);
  const [startDate, endDate] = dateRange;
  const [optionsForDestination, setOptionsForDestination] = useState(null);
  const [loading, setLoading] = useState(true); // Inicializa como true

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    clearErrorState();
    getAllContinentsWithDestinations()
      .subscribe((response) => {
        const destinationMap = new Map();

        response.data.forEach((item) => {
          item.destinations.forEach((destination) => {
            const continentName = item.name;
            const continentId = item.id;

            if (!destinationMap.has(continentId)) {
              destinationMap.set(continentId, {
                continentId,
                continentName,
                destinations: [],
              });
            }
            destinationMap.get(continentId).destinations.push(destination);
          });
        });

        const mappedOptions = [];
        destinationMap.forEach((continent) => {
          mappedOptions.push({
            value: continent.continentName,
            label: <strong>{continent.continentName}</strong>,
            type: 'continent',
            continentId: continent.continentId.toString(),
          });
          continent.destinations.forEach((destination) => {
            mappedOptions.push({
              value: destination.id.toString(),
              label: destination.name,
              type: 'destination',
              continentId: continent.continentId.toString(),
            });
          });
        });

        setOptionsForDestination(mappedOptions);
        setLoading(false);
      });
  }, []);

  const handleSearch = () => {
    window.scrollTo(0, 0);
    const queryParams = [];
    if (selectedDestination) {
      if (selectedDestination.type === "continent") {
        queryParams.push(`Continent=${selectedDestination.continentId}`);
      } else if (selectedDestination.type === "destination") {
        queryParams.push(`Destination=${selectedDestination.value}`);
      }
    }
    if (selectedTravelType) {
      queryParams.push(`CategoryId=${selectedTravelType.value}`);
    }
    if (startDate && endDate) {
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(0, 0, 0, 0);

      queryParams.push(`startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
    }
    queryParams.push('PageSize=12&PageNumber=1');

    history.push(`/package/search?${queryParams.join('&')}`);
  };

  const handleContinentSelection = (selectedOption) => {
    setSelectedDestination(selectedOption);
  };

  const handleDestinationSelection = (selectedOption) => {
    setSelectedDestination(selectedOption);
  };

  const optionsForTravel = [
    { value: "1", label: "Viagens em Grupo" },
    { value: "2", label: "Saídas Programadas" },
  ];

  // Exibe Skeleton para o componente inteiro se estiver carregando
  if (loading) {
    return (
      <div className="searchbar-section">
        <div className="container">
          <div className="multi-main-searchber">
            <Skeleton height={60} style={{ marginBottom: '10px' }} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="searchbar-section">
        <div className="container">
          <div className="multi-main-searchber">
            <div className="row g-4">
              <div className="col-lg-10">
                <div className="row gx-0 gy-4">
                  <div className="col-lg-4 col-md-6">
                    <div className="select-box">
                      <div className="searchbox-icons">
                        <i className="bi bi-geo-alt" />
                      </div>
                      <div className="searchbox-input">
                        <label htmlFor="activity-dropdown">Destinos</label>
                        <Select
                          defaultValue={selectedDestination}
                          onChange={(selectedOption) => {
                            if (selectedOption && selectedOption.type === 'continent') {
                              handleContinentSelection(selectedOption);
                            } else if (selectedOption && selectedOption.type === 'destination') {
                              handleDestinationSelection(selectedOption);
                            }
                          }}
                          options={optionsForDestination}
                          placeholder="Para onde você vai?"
                          className="selectbox"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="select-box">
                      <div className="searchbox-icons">
                        <i className="bi bi-text-paragraph" />
                      </div>
                      <div className="searchbox-input">
                        <label htmlFor="activity-dropdown">Tipo de viagem</label>
                        <Select
                          className="selectbox"
                          defaultValue={selectedTravelType}
                          placeholder="Todas as atividades"
                          onChange={setSelectedTravelType}
                          options={optionsForTravel}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="select-box">
                      <div className="searchbox-icons">
                        <i className="bi bi-capslock" />
                      </div>
                      <div className="searchbox-input">
                        <label htmlFor="activity-dropdown">Data</label>
                        <DatePicker 
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="main-form-submit">
                  <button type="button" onClick={handleSearch}>Buscar pacotes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchBar;
